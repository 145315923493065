<template>
  <!--
    Displays the devices detailed information.
  -->
  <table class="defaultTable deviceDetailtable">
    <tbody>
      <tr>
        <th 
          class="font-weight-bold text-right" 
          style="width:150px;"
        >
          {{ $t('deviceDetailTableComponent.status') }}
        </th>
        <td class="altCell">
          <span :class="device.vpnIpAddress ? 'green' : 'red'">
            <font-awesome-icon
              class="mr-1"
              :icon="device.vpnIpAddress ? 'plug' : 'ban'"
            />
            {{ device.vpnIpAddress ? 'Available' : 'Unavailable' }}
          </span>
        </td>
      </tr>

      <tr v-if="device.applicationType">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.applicationType') }}
        </th>
        <td>{{ device.applicationType }}</td>
      </tr>

      <tr v-if="device.vpnIpAddress">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.ipAddress') }}
        </th>
        <td>
          <CopyToClipboard :copy-data="device.vpnIpAddress" />
        </td>
      </tr>

      <tr v-if="!authenticationHasRole('zerolevel')">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.password') }}
        </th>
        <td>
          <PasswordViewer
            v-if="device.password"
            :password="device.password"
          />
        </td>
      </tr>

      <tr>
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.vpnType') }}
        </th>
        <td>{{ device.vpnType }}</td>
      </tr>

      <tr v-if="device.installedVersion">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.installedVersion') }}
        </th>
        <td>{{ device.installedVersion }}</td>
      </tr>

      <tr v-if="device.imageVersion">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.imageVersion') }}
        </th>
        <td>{{ device.imageVersion }}</td>
      </tr>

      <tr v-if="device.deviceType">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.deviceType') }}
        </th>
        <td>{{ device.deviceType }}</td>
      </tr>

      <tr v-if="device.applicationUrl">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.applicationUrl') }}
        </th>
        <td>{{ device.applicationUrl }}</td>
      </tr>

      <tr v-if="device.tags">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.tags') }}
        </th>
        <td>
          <span
            v-for="(tag, index) in device.tags"
            :key="`deviceDetailTable-tagbadge-${ index }`"
            class="badge badge-pill badge-info mr-1 mb-1"
          >{{ tag }}</span>
        </td>
      </tr>

      <tr v-if="device.mac">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.macAddress') }}
        </th>
        <td>{{ device.mac }}</td>
      </tr>

      <tr
        v-if="device.uuid"
        class="altRow altTwoCols"
      >
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.uuid') }}
        </th>
        <td>{{ device.uuid }}</td>
      </tr>

      <tr v-if="device.location">
        <th class="font-weight-bold text-right">
          {{ $t('deviceDetailTableComponent.location') }}
        </th>
        <td>{{ device.location }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { authenticationMixin } from '@/mixins/authenticationMixin';

export default {
  name: "DeviceDetailTable",
  components: {
    CopyToClipboard: () => import('@/components/CopyToClipboard.vue'),
    PasswordViewer: () => import('@/components/PasswordViewer.vue')
  },
  mixins: [
    authenticationMixin,
  ],
  props: {
    device: {
      type: Object,
      required: true
    }
  }
}
</script>
